import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { positions, Provider as AlertProvider, transitions } from 'react-alert'
import AlertTemplate from './AlertTemplate'
import { ThemeProvider } from 'styled-components'
import './i18n'

const LandingPage = React.lazy(() => import('./modules/LandingPage'))
const InvoiceApp = React.lazy(() => import('./modules/InvoiceApp'))
const ErrorPage = React.lazy(() => import('./modules/ErrorPage'))
const NotFound = React.lazy(() => import('./modules/NotFound'))

const queryClient = new QueryClient()

const theme = {
  colorBackground: '#ffffff',
  backgroundAccent: '#fafbfd',
  palette: {
    primary: '#ff5f50',
    textPrimary: '#45434D',
    textSecondary: '#777782',
    textAccent: '#BEBFC5',
  },
  success: '#2DCE00',
  error: '#FF5656',
  warning: '#ffdf56',
  rtl: true,
}

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <React.Suspense fallback={null}>
        <QueryClientProvider client={queryClient}>
          <AlertProvider
            position={positions.BOTTOM_CENTER}
            timeout={3500}
            offset={'100px'}
            transition={transitions.FADE}
            template={AlertTemplate}
            containerStyle={{
              zIndex: 9999,
            }}
          >
            <Switch>
              <Route path={'/error'} component={ErrorPage} exact />
              <Route path={'/not-found'} component={NotFound} exact />
              <Route path="/:url" component={InvoiceApp} />
              <Route path={'/'} component={LandingPage} />
            </Switch>
          </AlertProvider>
        </QueryClientProvider>
      </React.Suspense>
    </ThemeProvider>
  )
}

export default App
