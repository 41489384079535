import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter as Router } from 'react-router-dom'
// @ts-ignore
import SnapPixel from 'react-snapchat-pixel'
// @ts-ignore
import TwitterPixel from 'react-twitter-pixel'

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_TAG_MANAGER || '',
}
TagManager.initialize(tagManagerArgs)

SnapPixel.init(process.env.REACT_APP_SNAP_PIXEL_ID, undefined, { debug: true })
TwitterPixel.init(process.env.REACT_APP_TWITTER_PIXEL_ID, undefined, {
  debug: true,
})

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Suspense fallback={null}>
        <App />
      </Suspense>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
