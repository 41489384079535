import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from 'react-i18next'

// const lang = !!localStorage.getItem('language') ? localStorage.getItem('language') : 'ar';
const lang = 'ar';

i18n
  .use(Backend)
  .use(detector)
  .use(initReactI18next)
  .init({
    lng: lang,
    backend: {
      loadPath: '/i18n/{{ns}}/{{lng}}.json'
    },
    fallbackLng: 'ar',
    debug: process.env.NODE_ENV === "development",
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true
    }
  })

export default i18n