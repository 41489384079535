import React from 'react'
import { AlertComponentPropsWithStyle } from 'react-alert'
import styled from 'styled-components'
import { ReactComponent as CheckCircleIcon } from './images/check-circle.svg'

const AlertContainer = styled.div`
  padding: 5px;
  width: 90vw;
  max-width: 389px;
  background: white;
  border-radius: 15px;
  box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.1);
  .modal-content {
    direction: rtl;
    display: grid;
    grid-template-columns: 25px 1fr;
    margin: 20px;
    grid-gap: 10px;
    label {
      font-size: 14px;
      line-height: 16px;
    }
    svg {
      margin: auto;
      fill: ${(props) => props.theme.palette.primary};
    }
  }
  button {
    height: 35px;
    width: calc(100% - 20px);
    grid-column: span 2;
    background: ${(props) => props.theme.palette.primary};
    color: white;
    border: 0;
    outline: none;
    border-radius: 9px;
    margin: 0 10px 10px 10px;
    font-size: 15px;
    font-family: 'Cairo', Cambria, Cochin, Georgia, Times, 'Times New Roman',
      serif;
  }
`

const AlertTemplate = ({
  message,
  style,
  options,
}: AlertComponentPropsWithStyle) => {
  return (
    <AlertContainer style={style}>
      <div className="modal-content">
        {options.type === 'info' ? (
          <CheckCircleIcon width={20} height={20} color="#2DCE00" />
        ) : options.type === 'error' ? (
          <img src={require('./images/exclamation.svg')} alt="" />
        ) : null}
        <label>{message}</label>
      </div>
    </AlertContainer>
  )
}

export default AlertTemplate
